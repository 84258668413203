<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="input-group mb-3">
            <input
              ref="searchInput"
              v-model="searchQuery"
              @input="
                searchQuery = this.searchQuery;
                currentPage = 1;
              "
              type="search"
              class="form-control"
              placeholder="Search by punk ID, inscription hash..."
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="
                  sortBy = 'item';
                  sortOrder = 'asc';
                  currentPage = 1;
                  fetchItems();
                "
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="btn-group d-flex" role="group" aria-label="Sort Buttons">
            <button
              type="button"
              class="btn btn-secondary btn-sort flex-fill"
              :class="{ active: sortBy === 'item_id' && sortOrder === 'asc' }"
              @click="
                sortBy = 'item';
                sortOrder = 'asc';
                currentPage = 1;
                searchQuery = '';
                fetchItems();
              "
            >
              ID (up)
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-sort flex-fill"
              :class="{ active: sortBy === 'item_id' && sortOrder === 'desc' }"
              @click="
                sortBy = 'item';
                sortOrder = 'desc';
                currentPage = 1;
                searchQuery = '';
                fetchItems();
              "
            >
              ID (down)
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-sort flex-fill"
              :class="{
                active:
                  sortBy === 'item_first_inscription' && sortOrder === 'asc',
              }"
              @click="
                sortBy = 'inscription';
                sortOrder = 'asc';
                currentPage = 1;
                searchQuery = '';
                fetchItems();
              "
            >
              Earliest
            </button>
            <button
              type="button"
              class="btn btn-secondary btn-sort flex-fill"
              :class="{
                active:
                  sortBy === 'item_first_inscription' && sortOrder === 'desc',
              }"
              @click="
                sortBy = 'inscription';
                sortOrder = 'desc';
                currentPage = 1;
                searchQuery = '';
                fetchItems();
              "
            >
              Latest
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-full">
      <div class="row">
        <div class="col">
          <div class="grid-container">
            <div
              v-for="item in items"
              :key="item.item_id"
              class="grid-item"
              :class="{ disabled: item.item_inscribed === 0 }"
              @click="openModal(item.item_id)"
            >
              <!--
                <div>id: {{ item.item_id }}</div>
                <div>inscription: {{ item.item_first_inscription }}</div>
                <div>inscribed: {{ item.item_inscribed }}</div>
              -->
              <div class="item_id">#{{ item.item_id }}</div>
              <img
                v-show="imageLoaded"
                v-lazy="`images/items/punk${item.item_id}.png`"
                :alt="`punk${item.item_id}`"
                class="img-fluid"
                @load="imageLoaded = true"
              />
              <div
                v-show="!imageLoaded"
                class="spinner spinner-border text-secondary"
                role="status"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-full">
      <div class="row mt-3">
        <div class="col d-flex justify-content-center">
          <div class="btn-group" role="group" aria-label="Pagination Buttons">
            <button
              type="button"
              class="btn btn-secondary btn-sort flex-fill"
              :disabled="currentPage === 1"
              @click="
                currentPage--;
                fetchItems();
              "
            >
              Previous
            </button>
            <span class="btn btn-secondary disabled"
              >{{ currentPage }}/{{ Math.ceil(totalItems / 1000) }}</span
            >
            <button
              type="button"
              class="btn btn-secondary btn-sort flex-fill"
              :disabled="currentPage === Math.ceil(totalItems / 1000)"
              @click="
                currentPage++;
                fetchItems();
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <detail-popup-view
      :itemID="selecteditemID"
      @close-modal="closeModal"
    ></detail-popup-view>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../constants/appConstants";

/* global bootstrap */
import DetailPopupView from "./DetailPopupView.vue";

export default {
  name: "ListView",
  data() {
    return {
      items: [],
      totalItems: 0,
      currentPage: 1,
      searchQuery: "",
      sortBy: "item",
      sortOrder: "asc",
      selecteditemID: "",
      modalInstance: null,
      imageLoaded: false,
    };
  },
  components: {
    DetailPopupView,
  },
  mounted() {
    this.addCancelListener();
  },
  beforeUnmount() {
    this.removeCancelListener();
    window.removeEventListener("scroll", this.checkInView);
  },
  methods: {
    async fetchItems() {
      try {
        /*if (!this.searchQuery.endsWith('i0') || this.searchQuery.length < 10) {
          this.searchQuery = this.searchQuery.replace(/[^0-9]/g, '');
        }*/
        let { data } = await axios.get(
          `${BASE_URL}/items/${(this.currentPage - 1) * 1000}/1000/${
            this.sortBy
          }/${this.sortOrder}/${this.searchQuery}`
        );
        this.items = data.items;
        this.items = data.items.map((item) => ({ ...item, isInView: false }));
        this.totalItems = data.totalCount;
      } catch (error) {
        console.error(error);
      }
    },
    addCancelListener() {
      this.$refs.searchInput.addEventListener("search", this.handleCancel);
    },
    removeCancelListener() {
      this.$refs.searchInput.removeEventListener("search", this.handleCancel);
    },
    handleInput(event) {
      if (event.target.value === "") {
        this.handleCancel();
      }
    },
    handleCancel() {
      if (event.type === "search" && this.searchQuery === "") {
        this.currentPage = 1;
        this.searchQuery = "";
        this.sortBy = "item";
        this.sortOrder = "asc";
      }
      this.fetchItems();
    },
    openModal(itemID) {
      this.selecteditemID = itemID;
      this.modalInstance = new bootstrap.Modal(
        document.getElementById("detailPopup")
      );
      this.modalInstance.show();
    },
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    checkInView() {
      if (!this.$refs.itemGrid) {
        // check if the element is available
        return;
      }
      const items = this.$refs.itemGrid.children; // get the children elements
      const windowHeight = window.innerHeight; // get the window height
      const threshold = 200; // define a threshold value

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const rect = item.getBoundingClientRect(); // get the element's bounding client rect
        const isVisible =
          rect.top + threshold < windowHeight && rect.bottom - threshold > 0; // check if the element is visible within the threshold

        if (isVisible && !item.getAttribute("data-loaded")) {
          // check if the element is visible and not already loaded
          const itemImg = item.querySelector("img"); // get the image element
          const imgSrc = itemImg.dataset.src; // get the data-src attribute value
          itemImg.src = imgSrc; // set the image source
          itemImg.classList.add("fade-in"); // add a class to apply a fade in effect
          item.setAttribute("data-loaded", true); // set a flag to indicate the element has been loaded
        }
      }
    },
  },
  created() {
    this.fetchItems();
    window.addEventListener("scroll", this.checkInView);
  },
};
</script>

<style scoped>
.container-full {
  /* background-image: linear-gradient(90deg, #DFC66D 0%, #c8b059 100%); */
  padding: 2rem;
  margin: 1rem 0rem;
}
.btn {
  background-color: #dfc66d;
  color: #212020;
  box-shadow: none;
  border: 1px solid #c8b059;
}
.btn.active,
.btn:active,
.btn:focus,
.btn:hover,
.btn:active:focus {
  background-color: #212020 !important;
  box-shadow: none !important;
  border: 1px solid #c8b059 !important;
  color: #fff;
}
input::-webkit-search-cancel-button,
input::-webkit-search-decoration,
input:focus {
  -webkit-appearance: none;
  box-shadow: none !important;
  border: none !important;
}
input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1.4em;
  width: 1.4em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 8 8%22%3E%3Cline x1%3D%220%22 y1%3D%220%22 x2%3D%228%22 y2%3D%228%22 stroke%3D%22%23DFC66D%22 stroke-width%3D%221.5%22%2F%3E%3Cline x1%3D%220%22 y1%3D%228%22 x2%3D%228%22 y2%3D%220%22 stroke%3D%22%23DFC66D%22 stroke-width%3D%221.5%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  opacity: 0.5;
}
input::-webkit-search-cancel-button:hover {
  opacity: 1;
  cursor: pointer;
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.grid-item {
  position: relative;
  width: 100px; /* Or set the desired width */
  height: 100%; /* Adjust the height as needed */
  background-color: #dfc66d;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
}
.grid-item.disabled {
  opacity: 0.5;
  cursor: inherit;
}
.item_id {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #212020;
  color: #fff;
  padding: 0.1rem;
  margin-top: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.grid-item:hover .item_id {
  display: block;
}
.grid-item img {
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}
.spinner {
  margin: 1rem;
}
</style>
