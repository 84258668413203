<template>
  <div class="container-full">
    <p>Copyright &copy; 2023 Dogecoinpunks</p>
    <p class="small">To create this site, we look at the sha256 hash of every image sent to Dogecoin Ordinals and see if it matches any of the first 10,000 punk images. The Dogecoinpunks are the earliest records (with the lowest ID) on Ordinals that have these matching hash.</p>
    <p class="small">
    <strong>Disclaimer:</strong> We do our best to provide accurate information, but we are not responsible for any mistakes or inaccuracies. Please verify the information provided on this site before making any decisions or taking any actions based on it.
    </p>
  </div>
</template>


<script>

// import axios from "axios";
// import { BASE_URL } from "../constants/appConstants";

export default {
  name: 'FooterView',
}

</script>

<style scoped>
  .container-full {
      padding: 2rem 2rem 1rem 2rem;
      text-align: center;
      margin-top: 3.5rem;
      background-color: #000;
  }
  .small{
      font-size: 0.7rem;
  }
</style>