import { createRouter, createWebHistory } from "vue-router";
import ListView from "@/components/ListView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: ListView,
      meta: { title: "Home - Dogecoinpunks" },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const pageTitle = to.meta.title || "Dogecoinpunks"; // Set a default page title
  document.title = pageTitle;
  next();
});

export default router;
